import React from "react";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="Contact" id="contact">
      <h1 className="About-title">Contact</h1>
      <div className="Contact-container">
        <div className="grid-container">
          <form>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "15px",
                marginBottom: "15px",
              }}
              className="mobile-col"
            >
              <input type="text" id="name" name="name" placeholder="Name" />

              <input type="email" id="email" name="email" placeholder="Email" />
            </div>

            <textarea id="message" name="message" placeholder="Message" />

            <button type="submit" className="Contact-button">
              Send
            </button>
          </form>
        </div>
      </div>
      <footer className="Contact-footer">
        <div className="footer-links">
          <div className="footer-category">
            <h3>Community</h3>
            <ul>
              <li>
                <a
                  href="https://x.com/PanPanCoin"
                  target="_blank"
                  rel="noreferrer"
                >
                  X
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/FanFanOfficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  Telegram
                </a>
              </li>
            </ul>
          </div>
        </div>
        &copy; 2024 panpan. All rights reserved.
      </footer>
    </div>
  );
}
