import React, { useEffect, useRef } from "react";
import TextAnimation from "../Home/TextAnimation";
import { HashLink } from "react-router-hash-link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./About.css";

type AboutProps = {
  id: string;
};

export default function About({ id }: AboutProps) {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const rightRef_2 = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLHeadingElement>(null);
  const bottomRef_2 = useRef<HTMLDivElement>(null);
  const bottomRef_3 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBottom");
          observer3.unobserve(entry.target);
        }
      });
    }, options);

    if (leftRef.current) {
      observer.observe(leftRef.current);
    }
    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }
    if (rightRef_2.current) {
      observer2.observe(rightRef_2.current);
    }
    if (bottomRef.current) {
      observer3.observe(bottomRef.current);
    }
    if (bottomRef_2.current) {
      observer3.observe(bottomRef_2.current);
    }
    if (bottomRef_3.current) {
      observer3.observe(bottomRef_3.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="About" id={id}>
      <div className="About-container">
        <div
          className="opacity"
          ref={leftRef}
          style={{
            height: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{ width: "400px", height: "auto", gap: "20px" }}
            className="width-auto-mobile"
          >
            <h1 className="About-title titlemodel">About PanPan</h1>
            <TextAnimation
              text="More Than Just a Token, a Transformative Force in HEDERA's Trading Landscape! Embark on a journey with our groundbreaking DEX SWAP, revolutionizing trading dynamics for developers and traders alike. As PANPAN COIN emerges as a pivotal player in the HEDERA network, expect unparalleled solutions to industry challenges. Dedicated to fostering liquidity, maximizing yield, incubating new projects, and delivering seamless experiences, we pave the way for monumental shifts in project initiation, growth, and prosperity. Witness the emergence of a vibrant, forward-thinking ecosystem, where innovation and financial stability intersect. Join us in our relentless pursuit to ascend as the premier swap platform on HEDERA, shaping the future of decentralized trading"
              durationTime={2}
              textStyle={{
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "1.5em",
                color: "#fff",
              }}
              className="About-content"
            />
            <div className="About-button-box">
              <HashLink smooth to={"#howtobuy"}>
                Get Started
              </HashLink>
              <a href="/" target="_blank" rel="noreferrer">
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div
          className="opacity align-center"
          ref={rightRef}
          style={{ margin: "1rem 2rem" }}
        >
          <img src="/images/symbol.png" className="About-image" alt="horse" />
        </div>
      </div>
      {/* <div
        style={{
          height: "90vh",
          width: "100%",
        }}
        className="opacity"
        ref={bottomRef}
      >
        <h1 className="About-title gradient-text titlemodel">
          Why choose HORSGO?
        </h1>
        <Slider {...settings}>
          {FeedData.map((item) => (
            <div key={item.id} className="carousel-item">
              <div
                style={{ display: "flex", gap: "15px", padding: "1rem" }}
                className="auto-col"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "470px",
                  }}
                  className="auto-width"
                >
                  <div className="carousel-title">{item.title}</div>
                  <div className="carousel-content">{item.content}</div>
                  <a
                    className="carousel-link"
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Learn More</span>
                  </a>
                </div>

                <img
                  src={item.image}
                  alt={item.title}
                  width={250}
                  height={250}
                  className="carousel-image"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div> */}
      {/* <div
        className="opacity"
        style={{ margin: "0", gap: "2rem", height: "100vh", width: "auto" }}
        ref={bottomRef_2}
      >
        <h1 className="About-title titlemodel">About HORSGO NFT</h1>
        <div>
          <div style={{ display: "flex" }} className="About-mobile">
            <div style={{ width: "400px" }}>
              <TextAnimation
                text="Horsgo NFTs are broadly categorized into racehorses, stadiums, and items. You can breed your own racehorses or play matches in your own stadium. NFTs and $horsgo tokens are 100% owned by their owners, giving you full control over your assets."
                durationTime={2}
                textStyle={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "1.5em",
                  color: "#888888",
                }}
                className="About-content"
              />
              <div className="About-button-box">
                <a
                  href="https://document.horsgo.com/horsgo/horsgo-game/horse-racing/horse"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="align-center">
              <img src="/images/nfts.png" className="About-image" alt="nfts" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
