import React, { useEffect, useRef } from "react";
import "./HowToBuy.css";

declare global {
  interface Window {
    Jupiter: any;
  }
}

export default function HowToBuy() {
  const homeRef = useRef<HTMLDivElement>(null);
  const jupiterRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);
  const rightRef_2 = useRef<HTMLHeadingElement>(null);
  const rightRef_3 = useRef<HTMLHeadingElement>(null);
  const rightRef_4 = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBottom");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer3.unobserve(entry.target);
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    if (rightRef_2.current) {
      observer2.observe(rightRef_2.current);
    }

    if (rightRef_3.current) {
      observer2.observe(rightRef_3.current);
    }

    if (rightRef_4.current) {
      observer2.observe(rightRef_4.current);
    }

    if (jupiterRef.current) {
      observer3.observe(jupiterRef.current);
    }
    return () => {
      observer.disconnect();
      observer2.disconnect();
      observer3.disconnect();
    };
  }, []);

  return (
    <div className="HowToBuy" id="howtobuy">
      <div className="HowToBuy-container">
        <h1 className="About-title gradient-text titlemodel" ref={homeRef}>
          How to buy
        </h1>
        <h1 style={{ textAlign: "center" }}>or</h1>
        <div className="HowToBuy-small-container" ref={rightRef}>
          <img
            src="/images/metaMask.svg"
            alt="metaMask"
            className="HowToBuy-img"
          />
          <div>
            <h2 className="HowToBuy-subtitle">Create Wallet</h2>
            <p className="HowToBuy-text">
              You need a EVM wallet. We recommend using MetaMask.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_2}>
          <img
            src="/images/hedera-hbar-logo.svg"
            alt="hedera-hbar-logo"
            className="HowToBuy-img"
          />
          <div>
            <h2 className="HowToBuy-subtitle">Get some $HBAR</h2>
            <p className="HowToBuy-text">
              You need $HBAR to pay for transactions on the Hedera Hashgraph
              Network. You can buy $HBAR on Binance or Coinbase etc.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_3}>
          <img
            src="/images/SAUCE Token Icon.png"
            alt="swaps"
            className="HowToBuy-img"
          />
          <div>
            <h2 className="HowToBuy-subtitle">Go to Saucerswap</h2>
            <p className="HowToBuy-text">
              Go to Saucerswap and connect your wallet.
            </p>
          </div>
        </div>

        <div className="HowToBuy-small-container" ref={rightRef_4}>
          <img src="/images/symbol.png" alt="swaps" className="HowToBuy-img" />
          <div>
            <h2 className="HowToBuy-subtitle">Swap $HBAR for $panpan</h2>
            <p className="HowToBuy-text">
              Swap your $HBAR for $panpan on Saucerswap.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
