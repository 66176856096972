import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AccordionExpandDefault() {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#554b48" }}
          >
            What is PANPAN?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            PANPAN is the world’s most advanced consumer rading bot. More Than
            Just a Token, a Transformative Force in HEDERA's Trading Landscape!
            Embark on a journey with our groundbreaking DEX SWAP,
            revolutionizing trading dynamics for developers and traders alike.
            As PANPAN COIN emerges as a pivotal player in the HEDERA network,
            expect unparalleled solutions to industry challenges. Dedicated to
            fostering liquidity, maximizing yield, incubating new projects, and
            delivering seamless experiences, we pave the way for monumental
            shifts in project initiation, growth, and prosperity. Witness the
            emergence of a vibrant, forward-thinking ecosystem, where innovation
            and financial stability intersect. Join us in our relentless pursuit
            to ascend as the premier swap platform on HEDERA, shaping the future
            of decentralized trading
            <br />
            <br />
            PANPAN is currently in the private PRESALE stage when the price is
            at its lowest. When it goes public, the listing price will be much
            higher.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#554b48" }}
          >
            What is the PANPAN Presale?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            The PANPAN Presale allows buyers to secure PANPAN coins at a
            drastically lower price than the listing price. There are several
            stages, each lasting 2weeks. At each stage, the price will increase
            until the presale is over.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#554b48" }}
          >
            Is PANPAN A Meme Coin?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            PANPAN is not only A meme coin–it’s your ticket to better trading,
            and the native token of the first AI-powered trading bot of its
            kind.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{ fontWeight: "600", fontSize: "24px", color: "#554b48" }}
          >
            Do I need a crypto wallet to buy?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
            Yes, you will need a crypto wallet to buy and claim your presale
            $PANPAN. We recommend using Blade Wallet , a decentralised crypto
            wallet that makes it easy to buy crypto for the first time, as well
            as buy into token presale. Once you’ve set up and added funds to
            your Blade Wallet , you can connect to the widget at the top of the
            page to purchase some presale $PANPAN.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
