import React, { useEffect, useRef } from "react";
import "./Community.css";

export default function Community() {
  const rightRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    return () => {
      observer2.disconnect();
    };
  }, []);

  return (
    <div className="Community" id="community">
      <h1 className="Community-title">Community</h1>
      <div className="Community-container">
        <img
          src="/images/mockup.svg"
          alt="mockup"
          width={300}
          className="mockup"
        />
        <div className="Community-iconbtns opacity" ref={rightRef}>
          <a
            href=" https://t.me/FanFanOfficial"
            target="_blank"
            rel="noreferrer"
          >
            <h1>Telegram:</h1>
            <img src="/images/contact-telegram.svg" alt="etherscan" />
          </a>
          <a href="https://x.com/PanPanCoin" target="_blank" rel="noreferrer">
            <h1>X:</h1>
            <img src="/images/x-logo-red.svg" alt="twitter" />
          </a>
          <a
            href="/"
            target="_blank"
            rel="noreferrer"
            style={{ pointerEvents: "none" }}
          >
            <h1>Discord(coming soon):</h1>

            <img
              src="/images/discord.svg"
              alt="instagram"
              style={{ width: "46px" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
