import React, { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Widget from "./SwapWidget";
import "./Home.css";

type HomeProps = {
  id: string;
};

export default function Home({ id }: HomeProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const secondHeadingRef = useRef<HTMLHeadingElement>(null);
  const [ca, setCA] = useState("");

  const text = useRef<HTMLDivElement>(null);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    setCA("0x000000000000000000000000000000000056d82b");

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (secondHeadingRef.current) {
      observer2.observe(secondHeadingRef.current);
    }

    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <div className="Home" id={id}>
      <div ref={text} className="Home-container">
        <span className="Home-main-Title text-stroke">PanPan</span>
        <br />
        <span className="Home-sub-Title text-stroke">
          Ride the Panda Wave to Prosperity!
        </span>
        <div
          ref={secondHeadingRef}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px 0",
            width: "100%",
          }}
        >
          <img src="/images/main.svg" alt="home" className="Home-image" />
        </div>

        <div className="Home-iconbtns">
          {/* <a
            href="https://www.dextools.io/app/en/solana/pair-explorer/46ZvABPLmN3ZG6nH3HWbqF2kaYVXSk1qk8JXhQzXTgMT?t=1717491067373"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/dextools.svg"
              alt="dextools"
              width={38}
              height={38}
            />
          </a>
          <a
            href="https://dexscreener.com/solana/46zvabplmn3zg6nh3hwbqf2kayvxsk1qk8jxhqzxtgmt"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/dex-screener-seeklogo.png"
              alt="dexscreener"
              width={38}
              height={38}
            />
          </a> */}
          <a
            href="https://t.me/FanFanOfficial"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/telegram.svg"
              width={38}
              height={38}
              alt="telegram"
            />
          </a>
          <a
            href="https://t.me/+Xg41zf9pDT5lNmE1"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/telegram.svg"
              width={38}
              height={38}
              alt="telegram"
            />
          </a>
          <a href="https://x.com/PanPanCoin" target="_blank" rel="noreferrer">
            <img
              src="/images/x-logo.svg"
              alt="twitter"
              width={38}
              height={38}
            />
          </a>
          {/* <a href="/" target="_blank" rel="noreferrer">
            <img
              src="/images/discord.svg"
              alt="discord"
              width={38}
              height={38}
            />
          </a> */}
        </div>
        <div className="flex-container flex-direction-col flex-start justify-center gap-20">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="mobile-column"
          >
            <span
              style={{
                fontWeight: 500,
                margin: 0,
                fontSize: "14px",
                overflowWrap: "anywhere",
              }}
            >
              {ca}
            </span>
            <div style={{ display: "flex" }}>
              <button className="copy-btn" onClick={handleCopyToClipboard}>
                <ContentCopyIcon style={{ color: "white", width: "18px" }} />
              </button>
              {showPopup && (
                <div className="popup">
                  <p
                    style={{
                      margin: "0",
                      fontSize: "16px",
                      position: "absolute",
                    }}
                  >
                    Copied
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Widget />
    </div>
  );
}
