import React, { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "./Tokenomics.css";

am4core.useTheme(am4themes_animated.default);

type TokenomicsProps = {
  id: string;
};

export default function Tokenomics({ id }: TokenomicsProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);
  const bottomRef = useRef<HTMLHeadingElement>(null);
  const pieChartRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [ca, setCA] = useState("");

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    setCA("0x000000000000000000000000000000000056d82b");
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showBottom");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showRight");
          observer2.unobserve(entry.target);
        }
      });
    }, options);

    if (homeRef.current) {
      observer2.observe(homeRef.current);
    }

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);

  useEffect(() => {
    if (pieChartRef.current) {
      let chart = am4core.create(pieChartRef.current!, am4charts.PieChart);
      chart.data = [
        { category: "Presale", value: 33 },
        { category: "Staking", value: 12 },
        { category: "Community Rewards", value: 10 },
        { category: "DEX LP / CEX", value: 20 },
        { category: "Marketing", value: 25 },
      ];

      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "category";
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      pieSeries.slices.template.tooltipText =
        "{category}: {value.percent.formatNumber('#.0')}%";

      chart.legend = new am4charts.Legend();

      return () => {
        chart.dispose();
      };
    }
  }, []);

  return (
    <div className="Tokenomics" id={id}>
      <div
        className="Tokenomics-container opacity"
        ref={homeRef}
        style={{
          width: "fit-content",
          backgroundColor: "#f7f7f7",
          borderRadius: "24px",
          boxShadow: "1px 3px 5px rgba(0, 0, 0, 0.5)",
          padding: "1.5rem",
        }}
      >
        <h1 className="About-title titlemodel">Tokenomics</h1>
        <div className="grid-col-1fr">
          <div className="Tokenomics-subcontainer">
            <img src="/images/CA.png" alt="CA" />
          </div>

          <div className="flex-container flex-direction-col flex-start justify-center gap-20">
            <h1 className="Tokenomics-subtitle">CA</h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="mobile-column"
            >
              <span
                className="Tokenomics-subcontent"
                style={{
                  fontWeight: 500,
                  margin: 0,
                  overflowWrap: "anywhere",
                }}
              >
                {ca}
              </span>
              <div style={{ display: "flex" }}>
                <button className="copy-btn" onClick={handleCopyToClipboard}>
                  <ContentCopyIcon style={{ color: "black", width: "22px" }} />
                </button>
                {showPopup && (
                  <div className="popup">
                    <p
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        position: "absolute",
                      }}
                    >
                      Copied
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {window.innerWidth < 900 ? (
            <>
              <div className="Tokenomics-subcontainer">
                <img src="/images/supply.png" alt="supply" />
              </div>
              <div className="flex-container flex-direction-col flex-start justify-center gap-20">
                <h1 className="Tokenomics-subtitle">Supply</h1>
                <h1 className="Tokenomics-subcontent">9,000,000,000,000</h1>
              </div>
            </>
          ) : (
            <>
              <div className="flex-container flex-direction-col flex-start justify-center gap-20">
                <h1 className="Tokenomics-subtitle">Supply</h1>
                <h1 className="Tokenomics-subcontent">9,000,000,000,000</h1>
              </div>

              <div className="Tokenomics-subcontainer">
                <img src="/images/supply.png" alt="supply" />
              </div>
            </>
          )}

          <div className="Tokenomics-subcontainer">
            <img src="/images/SAFU.png" alt="tax" />
          </div>
          <div className="flex-container flex-direction-col flex-start justify-center gap-20">
            <h1 className="Tokenomics-subtitle">SAFU</h1>
            <h1 className="Tokenomics-subcontent">LP Tokens are burnt</h1>
          </div>
        </div>

        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            gap: "1rem",
          }}
          className="opacity"
          ref={bottomRef}
        >
          <h1 className="About-title titlemodel">Token Distribution</h1>
          <div
            className="pie-chart-container"
            ref={pieChartRef}
            style={{ width: "100%", height: "500px" }}
          ></div>
        </div>
      </div>
    </div>
  );
}
