import React, { useEffect, useState } from "react";

export default function Widget() {
  const [height, setHeight] = useState(700); // 초기 높이 설정
  const [width, setWidth] = useState("400px");

  useEffect(() => {
    // 모바일 장치인지 확인
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    // 모바일 장치라면 더 작은 높이로 설정
    if (isMobile) {
      setHeight(550);
      setWidth("95%");
    }
  }, []); // 한 번만 실행되도록 빈 배열 전달

  return (
    <iframe
      title="uniswap"
      src={`https://www.saucerswap.finance/swap/HBAR/0.0.5691435`}
      height={`${height}px`} // 높이를 동적으로 설정
      width={`${width}`}
      style={{
        border: "none",
        overflow: "hidden",
        borderRadius: "10px",
        boxShadow: "none", // 그림자 제거
        filter: "blur(0px)",
      }}
    />
  );
}
